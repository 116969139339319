import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import textGradient from "@/components/text-gradient.vue";
import pageHeader from "@/components/page-header.vue";
import flyLine from "@/components/fly-line.vue";
import printText from "@/components/print-text.vue";

const app = createApp(App);
app.component("textGradient", textGradient);
app.component("pageHeader", pageHeader);
app.component("flyLine", flyLine);
app.component("printText", printText);
app.use(store).use(router).mount("#app");
