<template>
  <div class="page-header">
    <div class="title-light-mask">
      <img src="@/assets/title-light.webp" class="title-light" />
    </div>
    <svg width="4300" height="81" class="dv-border-svg-container">
      <defs>
        <path
          :id="pathId"
          d="M0,28
                L1442,28
                L1448,38
                L1530,38
                L1560,74
                L2740,74
                L2770,38
                L2852,38
                L2858,28
                L4300,28"
          fill="transparent"
        ></path>
        <linearGradient :id="lgId" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style="stop-color: #0072ff; stop-opacity: 1">
            <animate
              attributeName="stop-color"
              values="#0072FF;#00EAFF;#0072FF"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="50%" style="stop-color: #00eaff; stop-opacity: 1">
            <animate
              attributeName="stop-color"
              values="#00EAFF;#0072FF;#00EAFF"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
        <radialGradient :id="rgId" cx="50%" cy="50%" r="50%">
          <stop offset="0%" stop-color="#fff" stop-opacity="1"></stop>
          <stop offset="100%" stop-color="#fff" stop-opacity="0"></stop>
        </radialGradient>
        <mask :id="maskId">
          <circle cx="0" cy="0" r="300" :fill="`url(#${rgId})`">
            <animateMotion
              dur="4s"
              path="M0,28
                L1442,28
                L1448,38
                L1530,38
                L1560,74
                L2740,74
                L2770,38
                L2852,38
                L2858,28
                L4300,28"
              rotate="auto"
              repeatCount="indefinite"
            ></animateMotion>
          </circle>
        </mask>
      </defs>
      <path
        d="M1442,28 L1536,28 L1575,74"
        stroke-width="1"
        stroke="#238DB9"
        fill="transparent"
      ></path>
      <path
        d="M2858,28 L2764,28 L2725,74"
        stroke-width="1"
        stroke="#238DB9"
        fill="transparent"
      ></path>
      <path d="M1465,40 L1491,74" stroke="#238DB9" fill="transparent"></path>
      <path
        d="M1473,40 L1499,74 L1550,74"
        stroke-width="2"
        stroke="#238DB9"
        fill="transparent"
      ></path>
      <path d="M2835,40 L2809,74" stroke="#238DB9" fill="transparent"></path>
      <path
        d="M2827,40 L2801,74 L2750,74"
        stroke-width="2"
        stroke="#238DB9"
        fill="transparent"
      ></path>

      <path
        d="M1482,43 L1500,43 L1522,70, L1504,70"
        :fill="`url(#${lgId})`"
      ></path>
      <path
        d="M1508,43 L1526,43 L1548,70, L1530,70"
        :fill="`url(#${lgId})`"
      ></path>

      <path
        d="M2818,43 L2800,43 L2778,70, L2796,70"
        :fill="`url(#${lgId})`"
      ></path>
      <path
        d="M2792,43 L2774,43 L2752,70, L2770,70"
        :fill="`url(#${lgId})`"
      ></path>

      <use
        stroke-width="2"
        :xlink:href="`#${pathId}`"
        stroke="#235fa7"
      ></use>
      <use
        stroke-width="10"
        :xlink:href="`#${pathId}`"
        :mask="`url(#${maskId})`"
        stroke="#4fd2dd"
      >
        <animate
          attributeName="stroke-dasharray"
          from="100, 0"
          to="100,0"
          dur="5s"
          repeatCount="indefinite"
        ></animate>
      </use>
    </svg>
    <div class="page-header__title">
      <text-gradient size="64" :color="['#00A8FF', '#00EAFF', '#00A8FF']">
        <slot />
      </text-gradient>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";


const pathId = ref(uuidv4())
const lgId = ref(uuidv4())
const rgId = ref(uuidv4())
const maskId = ref(uuidv4())
</script>

<style lang="less" scoped>
.page-header {
  width: 4300px;
  height: 80px;
  position: relative;
  box-sizing: border-box;
}
.page-header__title {
  font-family: PangMenZhengDao;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: 50px;
  line-height: 64px;
  // :deep(span) {
  //   letter-spacing: 25px;
  // }
}
@keyframes l-r-ani {
  0%{
    transform: translateX(410px);
  }
  100%{
    transform: translateX(0px);
  }
}
.title-light-mask{
  position: absolute;
  width: 1180px;
  height: 139px;
  top: 5px;
  left: calc(50% - 590px);
  // overflow: hidden;
}
.title-light {
  position: absolute;
  width: 810px;
  height: 139px;
  // top: 35px;
  // left: calc(50% - 1012.5px);
  transform: translateX(800px);
  animation: l-r-ani 2s alternate linear infinite;
}
</style>
