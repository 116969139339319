<template>
  <div>
    <div
      class="text-gradient"
      ref="textGradientRef"
      :style="{
        fontSize: size + 'px',
        background: background,
        'background-clip': 'text',
        fontWeight: bold ? 'bold' : '400',
      }"
    >
      <slot />
    </div>
    <svg v-if="svgStyle" :width="svgStyle.width" :height="svgStyle.height">
      <defs>
        <linearGradient
          :id="linearId"
          :x1="svgStyle.x1"
          :x2="svgStyle.x2"
          :y1="svgStyle.y1"
          :y2="svgStyle.y2"
        >
          <stop
            :offset="(index / (color.length - 1)) * 100 + '%'"
            :stop-color="item"
            v-for="(item, index) in color"
          ></stop>
        </linearGradient>
      </defs>
      <text
        :font-size="svgStyle.fontSize"
        :font-weight="bold ? 'bold' : '400'"
        x="0"
        :y="-2"
        :fill="'url(#' + linearId + ')'"
      >
        <tspan
          v-for="item in textArray"
          :x="svgStyle.width / 2"
          :dy="svgStyle.lineHeight"
          text-anchor="middle"
        >
          <tspan v-for="str in item" :opacity="str.opacity">{{ str.t }}</tspan>
        </tspan>
      </text>
    </svg>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { v4 as uuidv4 } from "uuid";

const textGradientRef = ref();
const props = defineProps({
  size: {
    type: String,
    default: "20",
  },
  color: {
    type: Array,
    default: () => {
      return ["#EAFFFE", "#09B3E0"];
    },
  },
  direction: {
    type: String,
    default: "180deg",
  },
  bold: {
    type: Boolean,
    default: false,
  },
  dur: {
    type: Number,
    default: 2000,
  },
  space: {
    type: Number,
    default: 1000,
  },
  print: Boolean,
  loop: Boolean
});

const svgStyle = ref();
const linearId = ref("");

const background = computed(() => {
  const colors = props.color.join(",");
  return `linear-gradient(${props.direction}, ${colors})`;
});

const cssToSvg = (angle) => {
  var deg = parseFloat(angle); // 调整角度
    if (deg === 0) {
        return [0, 0, 1, 0];
    } else if (deg === 90) {
        return [1, 0, 0, 0];
    } else if (deg === 180) {
        return [0, 0, 0, 1];
    } else if (deg === 270) {
        return [0, 1, 0, 0];
    }
    var rad = (deg * Math.PI) / 180; // 将角度转换成弧度
    var x1 = 0.5 - 0.5 * Math.cos(rad);
    var y1 = 0.5 + 0.5 * Math.sin(rad);
    var x2 = 0.5 + 0.5 * Math.cos(rad);
    var y2 = 0.5 - 0.5 * Math.sin(rad);
    return [x1, y1, x2, y2];
};

const textArray = ref([]);
const taskId = ref("");

const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, ms);
  });
};

const textAnimate = async (tid, text) => {
  let arr = text.split("\n");
  let textLength = text.replaceAll('\\n','').length
  const sleepTime = props.dur/textLength
  for (let i = 0; i < arr.length; i++) {
    let list = arr[i];
    for (let j = 0; j < list.length; j++) {
      
      if (taskId.value !== tid) {
        return;
      }
      if(!textArray.value[i]){
        textArray.value[i] = []
      }
      textArray.value[i].push({
        opacity: props.print ? 0 : 1,
        t: list[j]
      });
    }
  }
  if(!props.print){
    return
  }
  for(let i = 0; i< textArray.value.length; i++){
    let list = textArray.value[i];
    for(let j =0; j < list.length; j++){
      await sleep(sleepTime);
      list[j].opacity = 1;
    }
  }
  if(props.loop){
    await sleep(props.space)
    textArray.value = []
    if(props.space){
    }
    textAnimate(tid, text)
  }
};

onMounted(() => {
  linearId.value = uuidv4();
  const rect = getComputedStyle(textGradientRef.value);
  const angle = cssToSvg(props.direction);
  const fontSize = parseFloat(rect.fontSize);
  const lineHeight = rect.lineHeight === 'normal' ? fontSize * 1.2: parseFloat(rect.lineHeight)
  textArray.value = []
  taskId.value = uuidv4();
  textAnimate(taskId.value,textGradientRef.value.innerText);
  
  svgStyle.value = {
    width: parseFloat(rect.width),
    height: parseFloat(rect.height),
    fontSize: fontSize,
    text: textGradientRef.value.innerText.split("\n"),
    x1: angle[0],
    y1: angle[1],
    x2: angle[2],
    y2: angle[3],
    lineHeight: parseFloat(lineHeight) / fontSize + "em",
  };
});
</script>

<style lang="less" scoped>
.text-gradient {
  text-align: center;
  width: max-content;
  color: #ffffff; // 兜底颜色，防止文字裁剪不生效
  background-clip: text;
  -webkit-background-clip: text; // 背景被裁减为文字的前景色
  -webkit-text-fill-color: transparent; // 文字填充为透明，优先级比color高
  position: absolute;
  opacity: 0;
}
</style>
