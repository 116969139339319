<template>
  <div class="fly-line" :style="{ width: width + 'px', height: height + 'px' }">
    <svg :width="width" :height="height">
      <template v-for="item in lines">
        <move-line :config="{
            path: item.path,
            begin: item.begin,
            dur: item.dur,
            lineWidth: item.lineWidth,
            lineColor: item.lineColor,
            moveLineColor: item.moveLineColor,
            moveLineSize: item.moveLineSize,
            moveLineWidth: item.moveLineWidth
        }"></move-line>
      </template>
    </svg>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import moveLine from './move-line.vue'

const props = defineProps({
  width: {
    type: Number,
    default: 300,
  },
  height: {
    type: Number,
    default: 150,
  },
  points: {
    type: Array,
    default: () => {
      return [];
    },
  }
});

const lines = ref([]);
function getControlPoint(start, end, curvature = 0.5, direction = "left") {
  const [startX, startY] = start;
  const [endX, endY] = end;
  const midX = (startX + endX) / 2;
  const midY = (startY + endY) / 2;
  const dx = endX - startX;
  const dy = endY - startY;
  const distance = Math.sqrt(dx * dx + dy * dy);
  const controlPointDistance = distance * curvature;
  const angle =
    Math.atan2(dy, dx) + (direction === "right" ? Math.PI / 2 : -Math.PI / 2);
  const controlPointX = midX + controlPointDistance * Math.cos(angle);
  const controlPointY = midY + controlPointDistance * Math.sin(angle);
  return [controlPointX, controlPointY];
}

watch(
  () => props.points,
  () => {
    lines.value = props.points.map((item) => {
      let start = item.start;
      let end = item.end;
      let center = getControlPoint(start, end, item.curvature || 0.2, item.direction || 'left');
      let path = `M ${start[0]},${start[1]} Q${center[0]} ${center[1]} ${end[0]},${end[1]}`;
      
      return {
        path,
        begin:item.begin || '0s',
        dur:item.dur || '2s',
        lineWidth: item.lineWidth || '1',
        lineColor: item.lineColor || '#0D2B46',
        moveLineColor: item.moveLineColor || '#3AA6BC',
        moveLineSize: item.moveLineSize || '10',
        moveLineWidth:  item.moveLineWidth || '2'
      };
    });
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>

<style lang="less" scoped></style>
