<template>
  <router-view/>
</template>

<style lang="less">
@import url(@/assets/clear.less);
@import url(@/assets/main.less);

@font-face {
  font-family: 'PangMenZhengDao';
  src: url("@/assets/font/pmzd.TTF");
}

@font-face {
  font-family: 'DINPro-Medium';
  src: url("@/assets/font/DINPro-Medium.otf");
}
</style>
