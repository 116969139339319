import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/first",
  },
  {
    path: "/first",
    name: "first",
    component: () => import("../views/first/index.vue"),
  },
  {
    path: "/second",
    name: "second",
    component: () => import("../views/second/index.vue"),
  },
  {
    path: "/third",
    name: "third",
    component: () => import("../views/third/index.vue"),
  },
  {
    path: "/fourth",
    name: "fourth",
    component: () => import("../views/fourth/index.vue"),
  },
  {
    path: "/fifth",
    name: "fifth",
    component: () => import("../views/fifth/index.vue"),
  },
  {
    path: "/test-px",
    name: "test",
    component: () => import("../views/test/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
