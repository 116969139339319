<template>
  <g>
    <defs>
      <path :id="params.pathId" :d="params.path" fill="transparent"></path>
      <linearGradient
        v-if="params.lineGradient"
        :id="params.lineGradientId"
        :x1="params.lineGradient.direction[0]"
        :y1="params.lineGradient.direction[1]"
        :x2="params.lineGradient.direction[2]"
        :y2="params.lineGradient.direction[3]"
      >
        <stop
          :offset="item.offset"
          :stop-color="item.color"
          v-for="item in params.lineGradient.gradients"
        ></stop>
      </linearGradient>
      <radialGradient :id="params.gradientId" cx="50%" cy="50%" r="50%">
        <stop offset="0%" stop-color="#fff" stop-opacity="1"></stop>
        <stop offset="100%" stop-color="#fff" stop-opacity="0"></stop>
      </radialGradient>
      <mask :id="params.maskId">
        <circle
          cx="0"
          cy="0"
          :r="params.moveLineSize"
          :fill="`url(#${params.gradientId})`"
        >
          <animateMotion
            :dur="params.dur"
            :begin="params.begin"
            :path="params.path"
            rotate="auto"
            repeatCount="indefinite"
          >
          </animateMotion>
        </circle>
      </mask>
    </defs>
    <use
      :stroke-width="params.lineWidth"
      :xlink:href="`#${params.pathId}`"
      fill="transparent"
      :stroke="params.lineGradientId ? `url(#${params.lineGradientId})`:params.lineColor"
    ></use>
    <use
      :stroke-width="params.moveLineWidth"
      :xlink:href="`#${params.pathId}`"
      :mask="`url(#${params.maskId})`"
      :stroke="params.moveLineColor"
    >
      <animate
        attributeName="stroke-dasharray"
        :from="`${params.moveLineSize}, 0`"
        :to="`${params.moveLineSize}, 0`"
        repeatCount="indefinite"
      ></animate>
    </use>
  </g>
</template>

<script setup>
import { ref, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
const props = defineProps({
  config: Object,
  default: () => {
    return {
      path: "",
    };
  },
});

const params = ref({});

const cssToSvg = (angle) => {
  var deg = parseFloat(angle); // 调整角度
  if (deg === 0) {
    return [0, 1, 0, 0];
  } else if (deg === 90) {
    return [1, 0, 0, 0];
  } else if (deg === 180) {
    return [0, 0, 0, 1];
  } else if (deg === 270) {
    return [0, 0, 1, 0];
  }
  var rad = (deg * Math.PI) / 180; // 将角度转换成弧度
  var x1 = 0.5 - 0.5 * Math.cos(rad);
  var y1 = 0.5 + 0.5 * Math.sin(rad);
  var x2 = 0.5 + 0.5 * Math.cos(rad);
  var y2 = 0.5 - 0.5 * Math.sin(rad);
  return [x1, y1, x2, y2];
};

watch(
  () => props.config,
  () => {
    let lineGradient = props.config.lineGradientConfig;
    if (lineGradient) {
      lineGradient.direction = cssToSvg(lineGradient.direction);
    }
    
    params.value = {
      path: props.config.path,
      pathId: uuidv4(),
      gradientId: uuidv4(),
      maskId: uuidv4(),
      begin: props.config.begin || "0s",
      dur: props.config.dur || "2s",
      lineColor: props.config.lineColor || "#235fa7",
      lineWidth: props.config.lineWidth || "1",
      lineGradient: lineGradient,
      moveLineColor: props.config.moveLineColor || "#4fd2dd",
      moveLineWidth: props.config.moveLineWidth || "2",
      moveLineSize: props.config.moveLineSize || "10",
    };
    if(lineGradient){
      params.value.lineGradientId = uuidv4()
    }
  },
  {
    deep:true,
    immediate: true,
  }
);
</script>

<style lang="less" scoped></style>
