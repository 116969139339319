<template>
  <div class="print-text">
    <template v-for="(row, index) in textArray">
      <span v-for="item in row" :key="item" :style="{opacity: item.opacity}">{{ item.t }}</span>
      <br />
    </template>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { v4 as uuidv4 } from "uuid";

const props = defineProps({
  text: {
    type: String,
  },
  loop: Boolean,
  dur: {
    type: Number,
    default: 2000
  },
  space: {
    type: Number,
    default: 1000
  }
});

const textArray = ref([]);
const taskId = ref("");

const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, ms);
  });
};

const textAnimate = async (tid) => {
  let arr = props.text.split("\\n");
  let textLength = props.text.replaceAll('\\n','').length
  const sleepTime = props.dur/textLength
  for (let i = 0; i < arr.length; i++) {
    let list = arr[i];
    for (let j = 0; j < list.length; j++) {
      
      if (taskId.value !== tid) {
        return;
      }
      if(!textArray.value[i]){
        textArray.value[i] = []
      }
      textArray.value[i].push({
        opacity: 0,
        t: list[j]
      });
    }
  }
  for(let i = 0; i< textArray.value.length; i++){
    let list = textArray.value[i];
    for(let j =0; j < list.length; j++){
      await sleep(sleepTime);
      list[j].opacity = 1;
    }
  }
  if(props.loop){
    await sleep(props.space)
    textArray.value = []
    if(props.space){
    }
    textAnimate(tid)
  }
};

watch(
  () => props.text,
  () => {
    textArray.value = [[]];
    taskId.value = uuidv4();
    textAnimate(taskId.value);
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>

<style lang="less" scoped>
.print-text{
  span{
    transition: all 200ms;
  }
}
</style>
